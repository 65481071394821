<template>
  <div class="preview">
   <index :is_preview="1"></index>
  </div>
</template>

<script>
import index from '../collect/index.vue'
export default {
  components:{
    index
  }
}
</script>

<style>

</style>